
import { createRouter, createWebHashHistory } from "vue-router";

const home = () => import("../src/views/home")
const level = () => import("../src/views/level")
const share = () => import("../src/views/share")
const routes = [
  { path: "/", redirect: "/home" },
  {
    path: "/home",
    name: "home",
    component: home
  },
  {
    path: "/level",
    name: "level",
    component: level
  },{
    path: "/share",
    name: "share",
    component: share
  }
]

export const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
})